import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './screens/Login';
import Home from './screens/Home';
import Register from './screens/Register';
import Profile from './screens/Profile';
import Categories from './screens/Categories';
import Offer from './screens/Offer';
import AllOffers from './screens/AllOffers';
import EditOffer from './screens/EditOffer';
import Partners from './screens/Partners';
import UserTransaction from './screens/UserTransaction';
import SinglePartner from './screens/SinglePartner';
import SingleOffer from './screens/SingleOffer';
import AddBlogs from './screens/AddBlogs';
import AllBlogs from './screens/AllBlogs';
import EditBlog from './screens/EditBlog';
import Admins from './screens/Admins'
import StaticOffers from './screens/StaticOffers';
import PartnerOffer from './screens/PartnerOffer';
import LeaderBoard from './screens/LeaderBoard';
import MetaConfig from './screens/MetaConfig';
import GrossData from './screens/GrossData';
import AddPartner from './screens/AddPartner';
import EditPartner from './screens/EditPartner';
import PayPal from './screens/PayPal';
import ReferalLeaderBoard from './screens/ReferalLeaderBoard';
import WinnerLeaderBoard from './screens/WinnerLeaderBoard';
import EarningLeaderBoard from './screens/EarningLeaderBoard';
import AllUsers from './screens/AllUsers';
import PendingEarning from './screens/PendingEarning';
import Transaction2 from './screens/Transaction2';
import Challenge from './screens/Challenge';
import AllChallenge from './screens/AllChallenge';
import EditChallenge from './screens/EditChallenge';
import UserLogs from './screens/UserLogs';
import ReferLinkStat from './screens/ReferLinkStat';
import InvalidUrlPage from './screens/InvalidUrlPage';
import ConfigurationInfo from './screens/ConfigurationInfo';
import OneUserTask from './screens/OneUserTask';
import MultiPageData from './screens/MultiPageData';
import AllMultiPageData from './screens/AllMultiPageData';
import EditMultiPageData from './screens/EditMultiPageData';
import SingleUserAllTask from './screens/SingleUserAllTask';



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('')
  useEffect(() => {
    const jwtToken = localStorage.getItem('JWT');
    if (jwtToken) {
      setIsAuthenticated(true);
      const admindata = localStorage.getItem("admin");
      const adminArray = JSON.parse(admindata);
      setRole(adminArray?.role)
    }

  }, []);
  function ProtectedRoute({ children }) {
    const jwtToken = localStorage.getItem('JWT');

    if (!jwtToken) {
      return <Navigate to="/login" />;
    }
    return children;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><AllUsers /></ProtectedRoute>} />
          {role === "superAdmin" && <Route path="/admins" element={<ProtectedRoute><Admins /></ProtectedRoute>} />}
          {role === "superAdmin" && <Route path="/grossData" element={<ProtectedRoute><GrossData /></ProtectedRoute>} />}
          <Route path="/login" element={isAuthenticated ? <Home /> : <Login />} />
          <Route path="/register" element={isAuthenticated ? <Home /> : <Register />} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Login />} />
          {role !== "viewer" && <Route path="/partners/:id" element={isAuthenticated ? <SinglePartner /> : <Login />} />}
          {role !== 'viewer' && <Route path="/partners" element={isAuthenticated ? <Partners /> : <Login />} />}
          {role !== 'viewer' && <Route path="/AddPartner" element={isAuthenticated ? <AddPartner /> : <Login />} />}
          {role !== 'viewer' && <Route path="/editpartner/:id" element={isAuthenticated ? <EditPartner /> : <Login />} />}
          {role !== "viewer" && <Route path="/offer/:id" element={isAuthenticated ? <SingleOffer /> : <Login />} />}
          {role !== "viewer" && <Route path="/categories" element={isAuthenticated ? <Categories /> : <Login />} />}
          <Route path="/Home" element={isAuthenticated ? <Home /> : <Login />} />
          {role !== "viewer" && <Route path="/allOffers" element={isAuthenticated ? <AllOffers /> : <Login />} />}
          {role !== "viewer" && <Route path="/allBlogs" element={isAuthenticated ? <AllBlogs /> : <Login />} />}
          {role !== "viewer" && <Route path="/addBlog" element={isAuthenticated ? <AddBlogs /> : <Login />} />}
          {role !== "viewer" && <Route exact path="/offer" element={isAuthenticated ? <Offer /> : <Login />} />}
          {role !== "viewer" && <Route path="/editVoucher" element={isAuthenticated ? <EditOffer /> : <Login />} />}
          {role !== "viewer" && <Route path="/editBlog" element={isAuthenticated ? <EditBlog /> : <Login />} />}
          <Route path="/transaction" element={isAuthenticated ? <Transaction2 /> : <Login />} />
          <Route path="/pendingearning" element={isAuthenticated ? <PendingEarning /> : <Login />} />
          <Route path="/users/transaction/:id" element={isAuthenticated ? <UserTransaction /> : <Login />} />
          {role !== "viewer" && <Route path="/staticOffers" element={isAuthenticated ? <StaticOffers /> : <Login />} />}
          {role !== "viewer" && <Route path="/partnerOffer/:id" element={isAuthenticated ? <PartnerOffer /> : <Login />} />}
          {role !== "viewer" && <Route path="/metaconfig" element={isAuthenticated ? <MetaConfig /> : <Login />} />}
          {role !== "viewer" && <Route path="/leaderboard" element={isAuthenticated ? <LeaderBoard /> : <Login />} />}
          {role !== "viewer" && <Route path="/referalleaderboard" element={isAuthenticated ? <ReferalLeaderBoard /> : <Login />} />}
          {role !== "viewer" && <Route path="/winnerleaderboard" element={isAuthenticated ? <WinnerLeaderBoard /> : <Login />} />}
          {role !== "viewer" && <Route path="/earningleaderboard" element={isAuthenticated ? <EarningLeaderBoard /> : <Login />} />}
          {role !== "viewer" && <Route path="/paypal" element={isAuthenticated ? <PayPal /> : <Login />} />}
          {role !== "viewer" && <Route path='/challenge' element={isAuthenticated ? <Challenge /> : <Login />} />}
          {role !== "viewer" && <Route path='/allchallenge' element={isAuthenticated ? <AllChallenge /> : <Login />} />}
          {role !== "viewer" && <Route path='/editchallenge/:id' element={isAuthenticated ? <EditChallenge /> : <Login />} />}
          {role !== "viewer" && <Route path='/userLogs/:id' element={isAuthenticated ? <UserLogs /> : <Login />} />}
          {role !== "viewer" && <Route path='/referlinkstat' element={isAuthenticated ? <ReferLinkStat /> : <Login />} />}
          {role !== "viewer" && <Route path='/configurationInfo' element={isAuthenticated ? <ConfigurationInfo /> : <Login />} />}
          {role !== "viewer" && <Route path='/oneUserTask/:id' element={isAuthenticated ? <OneUserTask /> : <Login />} />}
          {role !== "viewer" && <Route path='/multiPageData' element={isAuthenticated ? <MultiPageData/> : <Login />} />}
          {role !== "viewer" && <Route path='/allmultiPageData' element={isAuthenticated ? <AllMultiPageData /> : <Login />} />}
          {role !== "viewer" && <Route path='/editmultiPageData/:id' element={isAuthenticated ? <EditMultiPageData /> : <Login />} />}
          {role !== "viewer" && <Route path='/singleUserAllTask/:id' element={isAuthenticated ? <SingleUserAllTask /> : <Login />} />}
          <Route path="*" element={<InvalidUrlPage />} />
        </Routes>
      </Router>
    </>

  );
}

export default App;
