import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import loader from '../assets/images/loader.gif'
import axiosClient from '../axios';
import { useParams } from 'react-router-dom';
import Pagination from './Pagination';
import moment from 'moment';



const OneUserTask = () => {
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams();
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });

    const [status, setStatus] = useState("");
    const taskStatusMap = {
        0: "New",
        1: "On Going",
        2: "Disqualified",
        3: "Completed",
        4: "Paused"
    };

    const getUserTaskData = () => {
        setIsLoading(false)
        axiosClient.get(`oneUserTask/${id}?page=${pagination.page}&limit=${pagination.limit}&status=${status}`)
            .then((res) => {
                setData(res?.data?.response)
                setTotalPage(res?.data?.userDocumentCount)
            })
            .catch((error) => { console.log(error) })
            .finally(() => setIsLoading(true))
    }

    useEffect(() => {
        getUserTaskData()
    }, [pagination?.page ,pagination?.limit])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"challenges"} />
            <div className="screen">
                <div className='taskUserInfoFlex'>
                    <h1>Total User {totalPage}</h1>
                    <div className='taskUserInfoFilter'>
                        {/* <label htmlFor="status">Select Status:</label> */}
                        <select
                            id="status"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            required
                            style={{ padding: "5px" }}
                        >
                            <option value="">--Select Status--</option>
                            <option value="0">New</option>
                            <option value="1">OnGoing</option>
                            <option value="2">Disqualified</option>
                            <option value="3">Completed</option>
                            <option value="4">Paused</option>
                        </select>
                        <button
                            className='userInfoSelectButton'
                            onClick={getUserTaskData}
                        >
                            Submit
                        </button>
                    </div>
                </div>


                <div className='profileInfoBox table'>
                    {isLoading ? <>
                        <div style={{ width: '100%' }} className="container app-body">
                            {data.length > 0 ? <>
                                <div className="tabel-scrol">
                                    <table id="users">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px' }}>S.NO.</th>
                                                {/* <th>taskId</th> */}
                                                <th>UserName </th>
                                                <th>Phone Number</th>
                                                {/* <th>challangeId</th> */}
                                                <th>TaskStatus</th>
                                                <th>TaskStartedAt</th>
                                                <th>TaskEndedAt</th>
                                                <th>TaskProgress Earnings</th>
                                                <th>TaskProgress CompletedChallenges</th>

                                                <th>timeTaken</th>
                                                <th>remainingTime</th>
                                                {/* <th>nPauses</th> */}
                                                {/* <th>includePartner</th> */}
                                                {/* <th>taskPausedAt</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((val, key) => (
                                                <tr>
                                                    <td>{(pagination.page * pagination.limit) + (key + 1)}</td>
                                                    {/* <td>{val?.taskId}</td> */}
                                                    <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val?.userId?._id}`)}>{val?.userId?.name}</td>
                                                    <td>{val?.userId?.phoneNumber}</td>
                                                    {/* <td>{val?.challangeId}</td> */}
                                                    {/* <td>{val?.taskStatus}</td> */}
                                                    <td>{taskStatusMap[val?.taskStatus]}</td>
                                                    <td>{moment.utc(val?.taskStartedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                    <td>{moment.utc(val?.taskEndedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>

                                                    {/* <td>{val?.taskStartedAt}</td> */}
                                                    {/* <td>{val?.taskEndedAt}</td> */}
                                                    <td>{val?.earnings.toFixed(2)}</td>
                                                    <td>{val?.completed_challenges}</td>

                                                    <td>{val?.timeTaken?.toFixed(2)}</td>
                                                    <td>{val?.remainingTime?.toFixed(2)}</td>
                                                    {/* <td>{val?.nPauses}</td> */}
                                                    {/* <td>{val?.includePartner}</td> */}
                                                    {/* <td>{val?.taskPausedAt}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                                <div>
                                    <Pagination
                                        filterDataInput={pagination}
                                        setFilterDataInput={setPagination}
                                        totalPage={totalPage}
                                    />
                                </div>
                            </> :
                                <>
                                    <h4 className='h5Heading'>No Data Available</h4>
                                </>}
                        </div>

                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default OneUserTask
