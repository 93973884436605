import React, { useEffect, useState } from 'react'
import axiosClient from '../axios';
import SideBar from './SideBar';
import "../assets/css/profile.css"
import ColorPickar from '../helperComponent/ColorPickar';
import JoditEditor from "jodit-react";
import { Autocomplete } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { TextField } from "@material-ui/core";
import { ReactComponent as Apple } from "../assets/images/apple.svg"
import { ReactComponent as Desktop } from "../assets/images/desktop.svg"
import { ReactComponent as Android } from "../assets/images/android.svg"
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import loader from "../assets/images/loader.gif";
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CustomizedHook from './CustomizedHook';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Offer() {
    const options = [
        { value: 1, label: 'Android', image: <Android /> },
        { value: 2, label: 'Desktop', image: <Desktop /> },
        { value: 3, label: 'Apple', image: <Apple /> }
    ];

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([1, 2]);
    const [vouhcerImage, setVoucherImage] = useState("")
    const [vouhcerCoverImage, setVouhcerCoverImage] = useState("")
    const [image1, setImage1] = useState("")
    const [image2, setImage2] = useState("")
    const [image3, setImage3] = useState("")
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [index, setIndex] = useState(0)
    const [editable, setEditable] = useState(false)

    const [allCategory, setAllCatgeory] = useState([]);
    const [allProvider, setAllProvider] = useState([]);


    const [colors, setColors] = useState({
        color1: "#2b579a",
        color2: "#2b579a"
    })

    const handleClose = () => {
        setOpen(false)
        setRewards({
            level: 0,
            payOutAmount: 0,
            revenueAmount: 0,
            minutesPlayed: 0
        })
        setEditable(false)
    }

    const [is_voucherActive, setIs_voucherActive] = useState("true");

    const [is_popular, setIs_popular] = useState("false");
    const [is_featured, setis_featured] = useState("false");

    const [formData, setFormData] = useState({
        name: "",
        steps: "",
        ratings: 0,
        rewards: [],
        trackLink: "",
        is_active: true,
        is_popular: false,
        is_featured: false,
        // is_recommended: false,
        description: "",
        important_tips: "",
        small_descriptions: "",
        conditions: "",
        category: "",
        provider: "",
        videoLink: "",
        countries: [],
        availabelOn: [1, 2],
        image: {
            base: "",
            coverImage: "",
            image1: "",
            image2: "",
            image3: ""
        },
        p_offer_id: '',
        is_easy: false,
        is_carousel: false,
        bg_color: []

    });

    const handleSelectChange = (event) => {
        setSelectedOptions(event.target.value);
        setFormData({ ...formData, availabelOn: event.target.value })
    };
    const [rewards, setRewards] = useState({
        level: 0,
        payOutAmount: 0,
        revenueAmount: 0,
        minutesPlayed: 0
    })

    const handlefileInput = async (e, type) => {
        setFileInputKey(Date.now());
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 6) {
            alert('File size is too large. Please choose a file smaller than 6 MB.');
        } else {
            photo.append("photo", e.target.files[0]);
            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    if (type === 1) {
                        setVouhcerCoverImage(res.data.response.url)
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                coverImage: res.data.response.url
                            }
                        })
                    } else if (type === 2) {
                        setVoucherImage(res.data.response.url)
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                base: res.data.response.url
                            }
                        })
                    }
                    else if (type === 3) {
                        setImage1(res.data.response.url)
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                image1: res.data.response.url
                            }
                        })
                    }
                    else if (type === 4) {
                        setImage2(res.data.response.url)
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                image2: res.data.response.url
                            }
                        })
                    }
                    else if (type === 5) {
                        setImage3(res.data.response.url)
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                image3: res.data.response.url
                            }
                        })
                    }

                })
                .catch((err) => console.log(err));
        }
    };


    const addReward = () => {
        if (!rewards?.level || !rewards?.payOutAmount || !rewards?.revenueAmount) {
            alert("level , payOutAmount , revenueAmount are mandatory field...")
            return
        }

        formData.rewards.push(rewards);
        setOpen(false)
        setRewards({
            level: "Installation",
            payOutAmount: 0,
            revenueAmount: 0,
            minutesPlayed: 0
        })
    }
    const navigate = useNavigate();

    const popReward = (e) => {
        formData.rewards.pop(e);
        setFormData({ ...formData, rewards: formData.rewards })
    }

    const handleEditReward = () => {
        let rewardData = [...formData?.rewards]
        rewardData[index] = rewards
        setFormData({ ...formData, rewards: rewardData })
        setOpen(false)
        setRewards({
            level: "Installation",
            payOutAmount: 0,
            revenueAmount: 0,
            minutesPlayed: 0
        })
        setEditable(false)
    }


    const getCatgeories = () => {
        axiosClient
            .get("/getCategory")
            .then((res) => setAllCatgeory(res.data.response))
            .catch((error) => console.log(error));
    }
    const handleSelectedValuesChange = (values) => {
        const namesArray = values.map(obj => obj);
        setFormData({ ...formData, countries: namesArray })
    };

    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }
    const sendData = () => {
        if (!formData?.name || !formData?.image || !formData?.trackLink || !formData?.description || !formData?.important_tips || !formData?.conditions || !formData?.small_descriptions || !formData?.countries) {
            alert("Missing Required Field")
            return
        }

        if ((formData?.rewards.length) < 1) {
            alert("Please Enter Rewards")
            return
        }

        formData.bg_color = [colors.color1, colors.color2]


        setLoading(true);
        axiosClient
            .post("/admin/newOffer", formData)
            .then((res) => {
                if (res.data.status === true) {
                    alert("Offer has been added succeffully")
                    navigate("/allOffers");
                } else {
                    console.log("some error")
                }
            })
            .catch((error) => {
                alert("Some error while adding offer")
            })
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        getCatgeories();
        getPartner();
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"offer"} />
            <div className='screen'>
                {loading ?
                    <img src={loader} alt="" className="loader" /> :
                    <>
                        <h2 className='screenHeading'>Add Offer</h2>
                        <div className='vouhcerInfoBox'>
                            <div className="addofferFlex">
                                <div className='editCover'>
                                    <p>
                                        Name
                                    </p>
                                    <input
                                        name="name"
                                        placeholder={'Name'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, name: e.target.value });
                                        }}
                                        value={formData.name}
                                        className="inputTag"
                                    />
                                </div>

                                <div className='editCover'>
                                    <p>
                                        Ratings
                                    </p>
                                    <input
                                        name="Number"
                                        placeholder={'Provider'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, ratings: e.target.value });
                                        }}
                                        value={formData.ratings}
                                        className="inputTag"
                                    />
                                </div>

                                <div className='editCover'>
                                    <p>
                                        P_Offer_Id
                                    </p>
                                    <input
                                        name="p_offer_id"
                                        placeholder={'p_offer_id'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, p_offer_id: e.target.value });
                                        }}

                                        value={formData.p_offer_id}
                                        className="inputTag"
                                    />
                                </div>
                            </div>
                            <div className='editCover editBio'>
                                <p>
                                    Track Link
                                </p>
                                <input
                                    name="name"
                                    placeholder={'TrackLink'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, trackLink: e.target.value });
                                    }}

                                    value={formData.trackLink}
                                    className="inputTag"
                                />
                            </div>
                            <div className='editCover editBio offer-m-top'>
                                <p>
                                    Video Link
                                </p>
                                <input
                                    name="videoLink"
                                    placeholder={'VideoLink'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, videoLink: e.target.value });
                                    }}
                                    value={formData.videoLink}
                                    className="inputTag"
                                />
                            </div>

                            <div className='addofferFlex offer-m-top'>

                                <div className='editCover'>
                                    <p>
                                        Is Offer active
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={is_voucherActive === "true" ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setIs_voucherActive(e.target.value);
                                                setFormData({ ...formData, is_active: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={is_voucherActive === "false" ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setIs_voucherActive(e.target.value);
                                                setFormData({ ...formData, is_active: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>

                                    </div>
                                </div>

                                <div className='editCover'>
                                    <p>
                                        Is Offer Popular
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={is_popular === "true" ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setIs_popular(e.target.value);
                                                setFormData({ ...formData, is_popular: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={is_popular === "false" ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setIs_popular(e.target.value);
                                                setFormData({ ...formData, is_popular: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>
                                    </div>
                                </div>

                                <div className='editCover'>
                                    <p>
                                        Is Featured
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={is_featured === "true" ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setis_featured(e.target.value);
                                                setFormData({ ...formData, is_featured: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={is_featured === "false" ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setis_featured(e.target.value);
                                                setFormData({ ...formData, is_featured: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>
                                    </div>
                                </div>

                                <div className='editCover'>
                                    <p>
                                        IS Easy
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={formData?.is_easy == true ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setFormData({ ...formData, is_easy: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={formData?.is_easy === false ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setFormData({ ...formData, is_easy: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>

                                    </div>
                                </div>
                                <div className='editCover'>
                                    <p>
                                        IS Carousel
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={formData?.is_carousel === true ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setFormData({ ...formData, is_carousel: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={formData?.is_carousel === false ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setFormData({ ...formData, is_carousel: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>
                                    </div>
                                </div>
                            </div>


                            <div className='addofferFlex offer-m-top'>
                                <div className='editCover offerDropown'>
                                    <p>
                                        Provider
                                    </p>
                                    <Autocomplete
                                        disablePortal
                                        value={formData?.provider.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setFormData({ ...formData, provider: newValue?._id });
                                            }
                                        }}
                                        options={allProvider}
                                        getOptionLabel={option => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Provider"
                                            />
                                        )}
                                    />
                                </div>

                                <div className='editCover offerDropown'>
                                    <p>
                                        Category
                                    </p>
                                    <Autocomplete
                                        disablePortal
                                        value={formData?.category.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setFormData({ ...formData, category: newValue?.name });
                                            }
                                        }}
                                        options={allCategory}
                                        getOptionLabel={option => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Category"
                                            />
                                        )}
                                    />
                                </div>

                                <div className='editCover offerDropown availableOn'>
                                    <p >
                                        Available On
                                    </p>
                                    <FormControl>
                                        <Select
                                            className='selectBox'
                                            multiple
                                            value={selectedOptions}
                                            onChange={handleSelectChange}
                                            renderValue={(selected) => (
                                                <div>
                                                    {selected.map((value) => {

                                                        const option = options.find((option) => option.value === value);
                                                        return (
                                                            <React.Fragment key={value}>
                                                                <span className='optionImage'>
                                                                    {option.image}
                                                                </span>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option.value} value={option.value} className="svgApple">
                                                    {option.image}
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <ColorPickar
                                colors={colors}
                                setColors={setColors}
                                heading={"Recommended Color"}

                            />

                            <div className='rewardTable offer-m-top'>
                                <p className='rewards'>Rewards <Add className='addReward' onClick={() => setOpen(true)} /></p>
                                <div className='flexRow'>
                                    <h3>Level</h3>
                                    <h3>Payout</h3>
                                    <h3>Revenue</h3>
                                    <h3>Minute Play</h3>
                                    <h3>Edit</h3>
                                    <h3>Delete</h3>
                                </div>
                                {
                                    formData?.rewards?.map((e, i) => {
                                        return (<React.Fragment key={i}>
                                            <div className='flexRow'>
                                                <h3>{e?.level}</h3>
                                                <h3>{e?.payOutAmount}</h3>
                                                <h3>{e?.revenueAmount}</h3>
                                                <h3>{e?.minutesPlayed}</h3>
                                                <h3>
                                                    <Edit onClick={() => {
                                                        setEditable(true)
                                                        setRewards(e)
                                                        setOpen(true)
                                                        setIndex(i)
                                                    }} />
                                                </h3>
                                                <h3>
                                                    <Delete onClick={() => popReward(i)} />
                                                </h3>

                                            </div>
                                        </React.Fragment>)
                                    })
                                }
                            </div>

                            <div className='offerCountryFilter'>
                                <div className='editCover responsiveSelect'>
                                    <CustomizedHook onSelectedValuesChange={handleSelectedValuesChange} />
                                </div>

                                <div className='editCover editBio'>
                                    <p>
                                        Selected Countries
                                    </p>
                                    {formData.countries.length > 0 ? <div>
                                        {formData.countries.map((e) => {
                                            return <span>{e.country} , </span>
                                        })}
                                    </div> : <>All Countries Selected</>}
                                </div>

                            </div>

                            <div className='offerImageFlex'>
                                <div className='editCover editBio'>
                                    <p>
                                        Offer Cover Image
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTagField">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTagField"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e, 1)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    {vouhcerCoverImage && <div>
                                        <img src={vouhcerCoverImage} alt="voucherimgae" className='voucher_image offerimage' />
                                    </div>}
                                </div>

                                <div className='editCover editBio'>
                                    <p>
                                        Offer Image
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTag">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTag"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e, 2)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    {vouhcerImage && <div>
                                        <img src={vouhcerImage} alt="voucherimgae" className='voucher_image offerimage' />
                                    </div>}
                                </div>



                                <div className='editCover editBio'>
                                    <p>
                                        Image1
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTag-Image1">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTag-Image1"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e, 3)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    {image1 && <div>
                                        <img src={image1} alt="voucherimgae" className='voucher_image offerimage' />
                                    </div>}
                                </div>

                                <div className='editCover editBio'>
                                    <p>
                                        Image2
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTag-Image2">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTag-Image2"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e, 4)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    {image2 && <div>
                                        <img src={image2} alt="voucherimgae" className='voucher_image offerimage' />
                                    </div>}
                                </div>


                                <div className='editCover editBio'>
                                    <p>
                                        Image3
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTag-Image3">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTag-Image3"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e, 5)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    {image3 && <div>
                                        <img src={image3} alt="voucherimgae" className='voucher_image offerimage' />
                                    </div>}
                                </div>



                            </div>


                            <div className='editCover editBio'>
                                <p>
                                    Descriptions
                                </p>
                                <div className="pRelative">
                                    <JoditEditor
                                        value={formData.question}
                                        onChange={(newContent) =>
                                            setFormData({ ...formData, description: newContent })
                                        }
                                    />
                                </div>
                            </div>

                            <div className='editCover editBio'>
                                <p>
                                    Important Tips
                                </p>
                                <div className="pRelative">
                                    <JoditEditor
                                        value={formData.question}
                                        onChange={(newContent) =>
                                            setFormData({ ...formData, important_tips: newContent })
                                        }
                                    />
                                </div>
                            </div>

                            <div className='editCover editBio'>
                                <p>
                                    Conditions
                                </p>
                                <div className="pRelative">
                                    <JoditEditor
                                        value={formData.question}
                                        onChange={(newContent) =>
                                            setFormData({ ...formData, conditions: newContent })
                                        }
                                    />
                                </div>
                            </div>

                            <div className='editCover editBio'>
                                <p>
                                    Small Description
                                </p>
                                <div className="pRelative">
                                    <JoditEditor
                                        value={formData.question}
                                        onChange={(newContent) =>
                                            setFormData({ ...formData, small_descriptions: newContent })
                                        }
                                    />
                                </div>
                            </div>
                        </div >
                        <div className='editButton' onClick={() => sendData()}>Add Offer</div>
                    </>
                }
            </div >

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'> Add Reward </h3>

                    <div className='editCover fullCover'>
                        <p>
                            Level
                        </p>
                        <input
                            name="level"
                            placeholder={'level'}
                            onChange={(e) => {
                                setRewards({ ...rewards, level: e.target.value });
                            }}
                            type="text"
                            value={rewards?.level}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Payout
                        </p>
                        <input
                            name="payout"
                            placeholder={'Payout'}
                            onChange={(e) => {
                                setRewards({ ...rewards, payOutAmount: e.target.value });
                            }}
                            type="number"
                            value={rewards.payOutAmount}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Revenue
                        </p>
                        <input
                            name="revenue"
                            placeholder={'Revenue'}
                            onChange={(e) => {
                                setRewards({ ...rewards, revenueAmount: e.target.value });
                            }}
                            type="number"
                            value={rewards.revenueAmount}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Minutes Play
                        </p>
                        <input
                            name="minute-play"
                            placeholder={'Minute'}
                            onChange={(e) => {
                                setRewards({ ...rewards, minutesPlayed: Number(e.target.value) });
                            }}
                            type="number"
                            value={rewards.minutesPlayed}
                            className="inputTag"
                        />
                    </div>
                    <div className='addButton' onClick={() => { editable ? handleEditReward() : addReward() }}>{editable ? "Edit" : "Add"}</div>
                </div>
            </Dialog>
        </>
    )
}

export default Offer